import LayoutLogin from '@/layout/LayoutLogin'

export default [{
  path: '/auth',
  component: LayoutLogin,
  children: [{
    path: 'login',
    component: () => import('@components/auth/LoginClient')
  }, {
    path: 'password/reset',
    component: () => import('@components/auth/PasswordReset')
  }]
}]
