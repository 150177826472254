import Layout1 from '@/layout/Layout1'

export default [{
  path: '/',
  component: Layout1,
  children: [
    {
      path: 'dashboard',
      component: () => import('@components/client/Dashboard')
    },
    {
      path: 'alerts',
      component: () => import('@components/client/Alerts')
    },
    {
      path: 'alert/',
      component: () => import('@components/client/AlertsSingle')
    },
    {
      path: 'alert/:id',
      component: () => import('@components/client/AlertsSingle')
    },
    {
      path: 'groups',
      component: () => import('@components/client/Groups')
    },
    {
      path: 'groups/:id',
      component: () => import('@components/client/GroupsSingle')
    },
    {
      path: 'areas',
      component: () => import('@components/client/Areas')
    },
    {
      path: 'team',
      component: () => import('@components/client/Team')
    },
    {
      path: 'profile',
      component: () => import('@components/client/Profile')
    },
    {
      path: 'support',
      component: () => import('@components/client/Support')
    },
    {
      path: 'ticket',
      component: () => import('@components/client/TicketsSingle')
    },
    {
      path: 'ticket/:id',
      component: () => import('@components/client/TicketsSingle')
    }
  ]
}]
